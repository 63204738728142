import { ref, watch } from '@vue/composition-api'

export default function useListasAdicionales() {
  const propsListTable = ref([])

  const tablePropsColumns = [
    // { text: 'Property ID', value: 'propertyId' },
    { text: '', value: 'controls' },
    { text: 'Address', value: 'address' },
    { text: 'Prop Type', value: 'propertyType', sortable: false },
    { text: 'Beds', value: 'propertyBeds', sortable: false },
    { text: 'Baths', value: 'propertyBaths', sortable: false },
    { text: 'Sqft', value: 'propertySqrFeet' },
    { text: 'Diff', value: 'propertySqrFeetValue', sortable: false },
    { text: 'Lot Size', value: 'propertyLotSize02', sortable: false },
    { text: 'Year', value: 'propertyYearBuilt' },
    { text: 'Diff', value: 'propertyYearDiff', sortable: false },
    { text: 'Sold/Listed', value: 'onRedfinDate', sortable: true },
    { text: 'Status', value: 'isSoldSale', sortable: false },
    { text: 'DOM', value: 'propertyDOM', sortable: false },
    { text: 'DTC', value: 'propertyDTC', sortable: false },
    { text: 'Price', value: 'listPrice', sortable: false },
    { text: '% Var', value: 'priceVariationPercent', sortable: false },
    { text: '$/Sqft', value: 'propertySqrPrice' },
    { text: 'Dist (mi)', value: 'distance' },
    { text: 'Is Selected', value: 'isSelected', sortable: false },
  ]

  const tableCompsColumns = [
    // { text: 'Property ID', value: 'propertyId' },
    { text: 'Legend', value: 'legendText', sortable: false },
    { text: 'Price Avg', value: 'listPriceAverage', sortable: false },
    { text: 'Sqft Avg', value: 'sqrFtPriceAverage', sortable: false },
    { text: 'DOM Avg', value: 'daysOnMarketAverage', sortable: false },
    { text: 'DTC Avg', value: 'daystoContractAverage', sortable: false },
    { text: 'ARV Avg', value: 'arvPriceAverage', sortable: false },
  ]

  const searchQuery = ref('')
  const options = ref({
    sortBy: ['distance desc',
    ],
    sortDesc: [true],
    itemsPerPage: 40,
  })

  // Opciones para filtro
  const optionsFiltro = ref({
    propertyId: '',
    propAddress: '',
    propCity: '',
    propState: '',
    propZipCode: '',
    market: '',
    status: '',
    propertyType: '',
    latitude: 0.0,
    longitude: 0.0,
    squareMin: 0,
    squareMax: 0,
    yearMin: 0,
    yearMax: 0,
    withinMin: 0.0,
    withinMax: 0.0,
    bedsMin: 0,
    bedsMax: 0,
    bathsMin: 0,
    bathsMax: 0,
    soldwithinMin: 0,
    soldwithinMax: 0,
  })

  const listaComparaciones = ref([
    {
      legendText: 'FOR SALE COMPS',
      listPriceAverage: 0.0,
      sqrFtPriceAverage: 0.0,
      daysOnMarketAverage: 0.0,
      daystoContractAverage: 0.0,
      arvPriceAverage: 0.0,
    },
    {
      legendText: 'SOLD COMPS',
      listPriceAverage: 0.0,
      sqrFtPriceAverage: 0.0,
      daysOnMarketAverage: 0.0,
      daystoContractAverage: 0.0,
      arvPriceAverage: 0.0,
    },
  ])

  const filterChanged = ref(false)

  const totalInvoiceListTable = ref(0)
  const loading = ref(false)
  const statusFilter = ref(null)
  const selectedTableData = ref([])

  watch([searchQuery, statusFilter, options], () => {
    // start loading
    loading.value = true
    selectedTableData.value = []
  })

  return {
    tablePropsColumns,
    searchQuery,
    options,
    propsListTable,
    statusFilter,
    totalInvoiceListTable,
    loading,
    selectedTableData,
    optionsFiltro,
    filterChanged,
    listaComparaciones,
    tableCompsColumns,
  }
}
