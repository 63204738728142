<template>
  <v-row class="app-invoice-preview">
    <v-col
      cols="12"
      md="12"
    >
      <prop-comp-editable
        :data="propData"
        :zoom="zoom"
        :center="center"
        :markers="markers"
        :checkedrows="checkedrows"
        :disablefilteryear="disablefilteryear"
        :disablefiltersqrt="disablefiltersqrt"
        :disablefilterbeds="disablefilterbeds"
        :disablefilterbaths="disablefilterbaths"
        :listacomparaciones="listaComparaciones"
        @onSelectedProperty="onSelectedProperty"
        @onCheckSelectedComps="onCheckSelectedComps"
        @onCheckSoldComps="onCheckSoldComps"
        @onCheckSaleComps="onCheckSaleComps"
        @onRefreshDataComps="onRefreshDataCompsv2"
        @onChangeFilter="onChangeFilter"
        @onCheckProperty02="onCheckProperty03"
        @onChangeMarket="onChangeMarket"
        @onChangeSelectedRows="onChangeSelectedRows"
        @onDisableFilterYear="onDisableFilterYear"
        @onDisableFilterSqrt="onDisableFilterSqrt"
        @onDisableFilterBeds="onDisableFilterBeds"
        @onDisableFilterBaths="onDisableFilterBaths"
        @onWebViewDetailsClick="onWebViewDetailsClick"
      >
      </prop-comp-editable>
    </v-col>

    <!-- Right Column: Invoice Action -->
  </v-row>
</template>

<script>
import Swal from 'sweetalert2'
import axios from '@axios'
import axios2 from 'axios'
import cheerio from 'cheerio'
import { DateTime } from 'luxon'
import { mdiSendOutline } from '@mdi/js'
import { ref, onUnmounted } from '@vue/composition-api'
import { icon } from 'leaflet'
import { OpenStreetMapProvider } from 'leaflet-geosearch'

// import { DateTime } from 'luxon'
import store from '@/store'
import PropCompEditable from './PropCompEditable.vue'
import propcompStore from './propcompStore'
import useListasAdicionales from './useListasAdicionales'

export default {
  components: {
    PropCompEditable,
  },
  methods: {
    async onSelectedProperty(item) {
      const address = `${item.item.address} ${item.item.city} ${item.item.state} ${item.item.zip}`

      // si fue seleccionado lo agregamos, sino lo removemos si existe.
      if (item.item.isSelected) {
        const iconmarker = item.item.isSoldSale ? this.iconSale : this.iconSold
        const tipomarker = item.item.isSoldSale ? 'sale' : 'sold'
        const marker = {
          id: item.item.propertyId, lat: item.item.latitude, lon: item.item.longitude, address, icon: iconmarker, tipo: tipomarker,
        }

        this.markers.push(marker)
      } else {
        const index = this.markers.findIndex(row => row.id === item.item.propertyId)
        if (index > 0) {
          this.markers.splice(index, 1)
        }
      }
      this.onCalculateComps()
    },
    async onCheckProperty02() {
      const address = `${this.propData.propData.address.replace(/\s+/g, '+')}%2C${this.propData.propData.city.replace(/\s+/g, '+')}%2C${this.propData.propData.state}%2CUSA`

      const url = `https://www.countyoffice.org/property-records-search/?q=${address}`

      const { data } = await axios2.get(url)

      const dataweb = cheerio.load(data)

      let latitude = 0
      let longitude = 0
      let year = ''
      let yearFirst = true
      let area = ''
      let parkingtype = ''
      let parkingspaces = ''

      const title = dataweb('.property-details tr')

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < title.length; i++) {
        // console.log(dataweb(title[i]).text())
        const textoheader = dataweb(title[i]).text().toLowerCase()
        if (
          textoheader.includes('year') && yearFirst
        ) {
          const td = title[i]
          const a = dataweb('td', td).first()
          year = dataweb(a).text()
          yearFirst = false
        }
        if (
          textoheader.includes('total area')
        ) {
          const td = title[i]
          const a = dataweb('td', td).first()
          area = dataweb(a).text()
        }
        if (
          textoheader.includes('parking type')
        ) {
          const td = title[i]
          const a = dataweb('td', td).first()
          parkingtype = dataweb(a).text()
        }
        if (
          textoheader.includes('parking spaces')
        ) {
          const td = title[i]
          const a = dataweb('td', td).first()
          parkingspaces = dataweb(a).text()
        }
      }

      const valuation = dataweb('#valuation td')
      const valueproperty = dataweb(valuation[1]).text()
      console.log(valueproperty)

      const mapgoogle = dataweb('#map')
      const mapproperty = dataweb(mapgoogle).attr('data-src')
      const words = mapproperty.split('&')
      words.forEach(element => {
        if (
          element.includes('center=')
        ) {
          const removeFirst = element.replace('center=', '')
          const words2 = removeFirst.split(',')
          console.log(words2)
          // eslint-disable-next-line prefer-destructuring
          latitude = words2[0]
          // eslint-disable-next-line prefer-destructuring
          longitude = words2[1]
        }
      })

      this.propData.propData.latitude = Number(latitude)
      this.propData.propData.longitude = Number(longitude)
      this.propData.propData.propertyYearBuilt = Number(year)
      this.propData.propData.propertySqrFeet = Number(area.replace(/[^0-9.-]+/g, ''))
      this.propData.propData.listPrice = Number(valueproperty.replace(/[^0-9.-]+/g, ''))
      this.propData.propData.propertyType = 'Single Family Residential'
      this.propData.propData.compPropertyType = 'Single Family Residential'
      this.propData.propData.propertySqrPrice = this.propData.propData.listPrice / this.propData.propData.propertySqrFeet

      if (parkingtype.includes('Garage')) {
        this.propData.propData.compGarage = Number(parkingspaces)
        this.propData.propData.compCarport = 0
        this.propData.propData.propertyNotes = `${parkingtype}: ${parkingspaces}`
      }

      if (parkingtype.includes('Carport')) {
        this.propData.propData.compCarport = Number(parkingspaces)
        this.propData.propData.compGarage = 0
        this.propData.propData.propertyNotes = `${parkingtype}: ${parkingspaces}`
      }

      this.propData.propData.filterYear = [this.propData.propData.propertyYearBuilt - 10, this.propData.propData.propertyYearBuilt + 10]

      this.propData.propData.filterSqrt = [this.propData.propData.propertySqrFeet - 500, this.propData.propData.propertySqrFeet + 500]

      const marker = {
        id: '000000', lat: this.propData.propData.latitude, lon: this.propData.propData.longitude, address,
      }

      this.markers = []
      this.markers.push(marker)
      this.zoom = 14
      this.center = [this.propData.propData.latitude, this.propData.propData.longitude]
      this.listpresented = 0

      // if (this.propData.popData.filterSoldProperties.length === 0 && this.propData.propData.filterSaleProperties.length === 0) {
      console.log('Checar listas vacias')
      this.filterChanged = true
      this.onCheckSoldComps()
      this.filterChanged = true
      this.onCheckSaleComps()

      setTimeout(() => {
        this.onCheckSelectedComps()
      }, 1000)
    },
    async onCheckProperty03() {
      try {
        const address = this.propData.propData.address.replace(/\s+/g, '%20')
        const city = this.propData.propData.city.replace(/\s+/g, '%20')
        const { state } = this.propData.propData
        const { zip } = this.propData.propData

        const url = `https://api.propmix.io/pubrec/assessor/v1/GetPropertyDetails?orderId=ERMASYSTEM&PostalCode=${zip}&State=${state}&City=${city}&StreetAddress=${address}`

        let { data } = await axios2.get(url, { headers: { 'Access-Token': '2ae8caeba178b07e95f76a79de2056158cc4f12b2827677947a6aaf88e4eaa92' } })

        const { Status, Data } = data

        if (Status.Code === 200) {
          this.propData.propData.latitude = Number(Data.Listing.Latitude)
          this.propData.propData.longitude = Number(Data.Listing.Longitude)
          this.propData.propData.address = Data.Listing.StreetAddress
          this.propData.propData.city = Data.Listing.City
          this.propData.propData.state = Data.Listing.StateOrProvince
          this.propData.propData.zip = Data.Listing.PostalCode
          this.propData.propData.propertyYearBuilt = Number(Data.Listing.YearBuilt)
          this.propData.propData.propertySqrFeet = Number(Data.Listing.BuildingAreaTotal)
          this.propData.propData.propertyLotSize = Number(Data.Listing.LotSizeSquareFeet)
          this.propData.propData.listPrice = Number(Data.Listing.ClosePrice)
          this.propData.propData.propertyBeds = Number(Data.Listing.BedroomsTotal)
          this.propData.propData.propertyBaths = Number(Data.Listing.BathroomsDecimal)
          this.propData.propData.marketEstimate = Number(Data.Listing.MarketValue)
          this.propData.propData.propertyType = Data.Listing.PropertySubTypeDescription
          this.propData.propData.compPropertyType = 'Single Family Residential' // Data.Listing.PropertySubTypeDescription
          this.propData.propData.propertySqrPrice = this.propData.propData.listPrice / this.propData.propData.propertySqrFeet

          if (Data.Listing.CarStorageType.includes('Garage')) {
            this.propData.propData.compGarage = Number(Data.Listing.ParkingSpaces)
            this.propData.propData.compCarport = 0
            this.propData.propData.propertyNotes = `${Data.Listing.CarStorageType}: ${Data.Listing.ParkingSpaces}`
          }

          if (Data.Listing.CarStorageType.includes('Carport')) {
            this.propData.propData.compCarport = Number(Data.Listing.ParkingSpaces)
            this.propData.propData.compGarage = 0
            this.propData.propData.propertyNotes = `${Data.Listing.CarStorageType}: ${Data.Listing.ParkingSpaces}`
          }

          this.propData.propData.filterYear = [this.propData.propData.propertyYearBuilt - 10, this.propData.propData.propertyYearBuilt + 10]

          this.propData.propData.filterSqrt = [this.propData.propData.propertySqrFeet - 500, this.propData.propData.propertySqrFeet + 500]

          const marker = {
            id: '000000', lat: this.propData.propData.latitude, lon: this.propData.propData.longitude, address, tipo: 'root',
          }

          this.markers = []
          this.markers.push(marker)
          this.zoom = 14
          this.center = [this.propData.propData.latitude, this.propData.propData.longitude]
          this.listpresented = 0

          // if (this.propData.popData.filterSoldProperties.length === 0 && this.propData.propData.filterSaleProperties.length === 0) {
          console.log('Checar listas vacias')
          this.filterChanged = true
          this.onCheckSoldComps()
          this.filterChanged = true
          this.onCheckSaleComps()

          // por ultimo obtenemos el listado, si existe de detalles de la propiedad.
          const urlDetails = `${process.env.VUE_APP_API_URL}/api/propertieslist/leerlistapropiedades?address=${this.propData.propData.address}&city=${this.propData.propData.city}`
          data = await axios.get(urlDetails)

          // console.log(status)
          // console.log(data)

          if (data.status === 200) {
            // eslint-disable-next-line no-restricted-syntax
            for (const property of data.data) {
              if (property.marketProvider === 'ZILLOW') {
                this.propData.propData.estimateZillow = property.estimateZillow

                // window.open(property.url, '_blank')
              }
              if (property.marketProvider === 'REALTOR') {
                this.propData.propData.estimateRealtor01 = property.estimateRealtor01
                this.propData.propData.estimateRealtor02 = property.estimateRealtor02
                this.propData.propData.estimateRealtor03 = property.estimateRealtor03

                // window.open(property.url, '_blank')
              }
              if (property.marketProvider === 'REDFIN') {
                this.propData.propData.estimateRedfin = property.estimateRedfin

                // window.open(property.url, '_blank')
              }
            }
          }

          const direccion = this.propData.propData.address.replace(/\s+/g, '-')
          const ciudad = this.propData.propData.city.replace(/\s+/g, '-')
          const urlgoogle = `https://www.google.com/search?q=site%3A+realtor.com+${direccion}%2C+${ciudad}%2C+AZ`
          window.open(urlgoogle, '_blank')

          setTimeout(() => {
            this.center = [this.propData.propData.latitude, this.propData.propData.longitude]
            this.onCheckSelectedComps()
          }, 1000)
        }
      } catch (err) {
        console.log(err)
        Swal.fire({
          text: 'The API can not find any match for the address provided. Please check the address or try in another moment...',
          toast: true,
          position: 'top-right',
          icon: 'error',
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false,
        })
      }
    },
    async onChangeMarket(value) {
      let market
      store
        .dispatch('app-invoice/fetchMarket', { id: value.$event })
        .then(response => {
          market = response.data
          this.center = [market.marketLatitude, market.marketLongitude]
          this.zoom = 11
        })
        .catch(error => {
          console.log(error)
        })
    },
    async onChangeSelectedRows(header) {
      console.log(this.listpresented)
      if (!header.checkedrows) {
        console.log('if checkedrows')
        if (this.listpresented === 0 || this.listpresented === 1) {
          console.log('if checkedrows-sold')
          // eslint-disable-next-line no-restricted-syntax
          for (const property of this.propData.propData.filterSoldProperties) {
            property.isSelected = false
            const index = this.markers.findIndex(row => row.id === property.propertyId)
            if (index > 0) {
              this.markers.splice(index, 1)
            }
          }
        }

        if (this.listpresented === 0 || this.listpresented === 2) {
          console.log('if checkedrows-sale')
          // eslint-disable-next-line no-restricted-syntax
          for (const property of this.propData.propData.filterSaleProperties) {
            property.isSelected = false
            const index = this.markers.findIndex(row => row.id === property.propertyId)
            if (index > 0) {
              this.markers.splice(index, 1)
            }
          }
        }

        if (this.listpresented === 0 || this.listpresented === 1) {
          this.propData.propData.filterListProperties = this.propData.propData.filterSoldProperties
          this.listpresented = 1
        } else {
          this.propData.propData.filterListProperties = this.propData.propData.filterSaleProperties
          this.listpresented = 2
        }

        this.onCalculateComps()
      } else {
        console.log('else checkedrows')
        if (this.listpresented === 0 || this.listpresented === 1) {
          console.log('if checkedrows-sold')
          // eslint-disable-next-line no-restricted-syntax
          for (const property of this.propData.propData.filterSoldProperties) {
            const iconmarker = property.isSoldSale ? this.iconSale : this.iconSold
            property.isSelected = true

            // Si ya existe entonces no lo agregamos
            const index = this.markers.findIndex(row => row.id === property.propertyId)
            if (index < 0) {
              const marker2 = {
                id: property.propertyId, lat: property.latitude, lon: property.longitude, address: property.address, icon: iconmarker, tipo: 'sold',
              }
              this.markers.push(marker2)
            }
          }
        }

        if (this.listpresented === 0 || this.listpresented === 2) {
          console.log('if checkedrows-sale')
          // eslint-disable-next-line no-restricted-syntax
          for (const property of this.propData.propData.filterSaleProperties) {
            const iconmarker = property.isSoldSale ? this.iconSale : this.iconSold
            property.isSelected = true

            // Si ya existe entonces no lo agregamos
            const index = this.markers.findIndex(row => row.id === property.propertyId)
            if (index < 0) {
              const marker2 = {
                id: property.propertyId, lat: property.latitude, lon: property.longitude, address: property.address, icon: iconmarker, tipo: 'sale',
              }
              this.markers.push(marker2)
            }
          }
        }

        if (this.listpresented === 0 || this.listpresented === 1) {
          this.propData.propData.filterListProperties = this.propData.propData.filterSoldProperties
          this.listpresented = 1
        } else {
          this.propData.propData.filterListProperties = this.propData.propData.filterSaleProperties
          this.listpresented = 2
        }

        this.onCalculateComps()
      }
    },
    async onWebViewDetailsClick(props) {
      const { address, city } = props.props.item

      const url = `${process.env.VUE_APP_API_URL}/api/propertieslist/leerlistapropiedades?address=${address}&city=${city}`
      const { status, data } = await axios.get(url)

      console.log(status)
      console.log(data)

      if (status === 200) {
        window.open(data[0].url, '_blank')
        window.open(data[1].url, '_blank')
      }

      // const address = props.props.item.address.replace(/\s+/g, '-')
      // const city = props.props.item.city.replace(/\s+/g, '-')
      // const { state } = props.props.item
      // const { zip } = props.props.item
      // const urlzillow = `https://www.zillow.com/homes/${address},-${city},-${state},-${zip}`
      // window.open(props.props.item.url, '_blank')
      // window.open(urlzillow, '_blank')
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) {
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, propcompStore)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const provider = new OpenStreetMapProvider()

    // Opciones para filtro
    const {
      optionsFiltro, filterChanged, listaComparaciones,
    } = useListasAdicionales()

    const zoom = ref(12)
    const checkedrows = ref(true)
    const disablefilteryear = ref(false)
    const disablefiltersqrt = ref(false)
    const disablefilterbeds = ref(false)
    const disablefilterbaths = ref(false)
    const listpresented = ref(0)

    const center = ref([31.560953, -110.38036])
    const markers = ref([])

    const iconSale = icon({
      iconUrl: 'images/marker-icon-green.png',
      shadowUrl: 'images/marker-shadow.png',
      iconSize: [25, 41],
      iconAnchor: [20, 20],
      shadowSize: [41, 41],
      shadowAnchor: [20, 20],
    })

    const iconSold = icon({
      iconUrl: 'images/marker-icon-red.png',
      shadowUrl: 'images/marker-shadow.png',
      iconSize: [25, 41],
      iconAnchor: [20, 20],
      shadowSize: [41, 41],
      shadowAnchor: [20, 20],
    })

    const propData = ref({
      propData: {
        id: null,
        propertyId: null,
        market: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        listPrice: 0,
        onRedfin: '',
        onRedfinDate: DateTime.now().toISO(),
        latitude: 0.0,
        longitude: 0.0,
        propertyBeds: 0,
        propertyBaths: 0,
        propertySqrFeet: 0,
        propertySqrPrice: 0,
        propertyYearBuilt: 0,
        propertyLotSize: 0,
        propertyNotes: '',
        compPool: 0,
        compHoa: 0,
        compGarage: 0,
        compWaterfront: false,
        compHasview: false,
        compHaspool: false,
        compGuesthouse: false,
        compRv: false,
        compCarport: 0,
        compPropertyType: 'Single Family Residential',
        comparisonStatus: 'Active',
        filterpropType: '',
        filterSqrtMin: 0,
        filterSqrtMax: 3000,
        filterSqrt: [0, 3000],
        filterYearMin: 1950,
        filterYearMax: 2022,
        filterYear: [1950, 2022],
        filterWhitinMin: 0.0,
        filterWhitinMax: 1.0,
        filterWhitin: [0.0, 0.5],
        filterBedsMin: 0,
        filterBedsMax: 5,
        filterBeds: [0, 5],
        filterBathsMin: 0,
        filterBathsMax: 5,
        filterBaths: [0, 5],
        filterSoldwhitinMin: 0,
        filterSoldwhitinMax: 12,
        filterSoldwhitin: [0, 12],
        filterListProperties: [],
        filterSaleProperties: [],
        filterSoldProperties: [],
        listPriceValue: 0.0,
        propertyBedsValue: 0.0,
        propertyBathsValue: 0.0,
        propertySqrFeetValue: 0.0,
        propertySqrPriceValue: 0.0,
        propertyLotSizeValue: 0.0,
        compPoolValue: 0.0,
        compHoaValue: 0.0,
        compGarageValue: 0.0,
        compBasementValue: 0.0,
        compWaterfrontValue: 0.0,
        compViewValue: 0.0,
        compGuesthouseValue: 0.0,
        compRvValue: 0.0,
        compCarportValue: 0.0,
        marketEstimate: 0.0,
        estimateZillow: 0.0,
        estimateRedfin: 0.0,
        estimateRealtor01: 0.0,
        estimateRealtor02: 0.0,
        estimateRealtor03: 0.0,
      },
    })

    const onChangeFilter = () => {
      filterChanged.value = true
    }

    const onDisableFilterYear = () => {
      disablefilteryear.value = !disablefilteryear.value
      filterChanged.value = true
    }

    const onDisableFilterSqrt = () => {
      disablefiltersqrt.value = !disablefiltersqrt.value
      filterChanged.value = true
    }

    const onDisableFilterBeds = () => {
      disablefilterbeds.value = !disablefilterbeds.value
      filterChanged.value = true
    }

    const onDisableFilterBaths = () => {
      disablefilterbaths.value = !disablefilterbaths.value
      filterChanged.value = true
    }

    const onCheckSelectedComps = () => {
      listpresented.value = 0
      const filterSold = propData.value.propData.filterSoldProperties.filter(obj => obj.isSelected === true)
      const filterSale = propData.value.propData.filterSaleProperties.filter(obj => obj.isSelected === true)

      propData.value.propData.filterListProperties = [...filterSold, ...filterSale]
      propData.value.propData.filterListProperties.sort((a, b) => ((a.distance > b.distance) ? 1 : -1))
    }

    function onCalculateComps() {
      console.log('Checamos calculos...')
      if (propData.value.propData.filterSoldProperties.length > 0) {
        let sumDays = 0
        let countDays = 0
        let sumDaysContract = 0
        let countDaysContract = 0
        let sumPrice = 0
        let sumSqftPrice = 0
        let selectedProperties = 0

        // eslint-disable-next-line no-restricted-syntax
        for (const property of propData.value.propData.filterSoldProperties) {
          if (property.isSelected) {
            sumPrice += property.listPrice
            sumSqftPrice += property.propertySqrPrice
            sumDays += property.propertyDOM
            sumDaysContract += property.propertyDTC
            selectedProperties += 1
            if (property.propertyDOM > 0) {
              countDays += 1
            }
            if (property.propertyDTC > 0) {
              countDaysContract += 1
            }
          }
        }
        listaComparaciones.value[1].listPriceAverage = (sumPrice / selectedProperties).toFixed(2)
        listaComparaciones.value[1].sqrFtPriceAverage = (sumSqftPrice / selectedProperties).toFixed(2)
        listaComparaciones.value[1].daysOnMarketAverage = (sumDays / countDays).toFixed(0)
        listaComparaciones.value[1].daystoContractAverage = (sumDaysContract / countDaysContract).toFixed(0)
        listaComparaciones.value[1].arvPriceAverage = (listaComparaciones.value[1].sqrFtPriceAverage * propData.value.propData.propertySqrFeet).toFixed(2)
      }
      if (propData.value.propData.filterSaleProperties.length > 0) {
        let sumDays = 0
        let countDays = 0
        let sumDaysContract = 0
        let countDaysContract = 0
        let sumPrice = 0
        let sumSqftPrice = 0
        let selectedProperties = 0

        // eslint-disable-next-line no-restricted-syntax
        for (const property of propData.value.propData.filterSaleProperties) {
          if (property.isSelected) {
            sumPrice += property.listPrice
            sumSqftPrice += property.propertySqrPrice
            sumDays += property.propertyDOM
            sumDaysContract += property.propertyDTC
            selectedProperties += 1

            // const dateConv = DateTime.fromISO(property.onRedfinDate)

            // property.propertyDOM = dateConv.diffNow('days').days * -1
            // sumDays += dateConv.diffNow('days').days
            if (property.propertyDOM > 0) {
              countDays += 1
            }
            if (property.propertyDTC > 0) {
              countDaysContract += 1
            }
          }
        }
        listaComparaciones.value[0].listPriceAverage = (sumPrice / selectedProperties).toFixed(2)
        listaComparaciones.value[0].sqrFtPriceAverage = (sumSqftPrice / selectedProperties).toFixed(2)

        // listaComparaciones.value[0].daysOnMarketAverage = ((sumDays / selectedProperties) * -1).toFixed(0)
        listaComparaciones.value[0].daysOnMarketAverage = (sumDays / countDays).toFixed(0)
        listaComparaciones.value[0].daystoContractAverage = (sumDaysContract / countDaysContract).toFixed(0)
        listaComparaciones.value[0].arvPriceAverage = (listaComparaciones.value[0].sqrFtPriceAverage * propData.value.propData.propertySqrFeet).toFixed(2)
      }
    }

    const onCheckSoldComps = () => {
      listpresented.value = 1
      if (filterChanged.value || !propData.value.propData.filterSoldProperties.length) {
        optionsFiltro.value.propertyId = propData.value.propData.propertyId
        optionsFiltro.value.propAddress = propData.value.propData.address
        optionsFiltro.value.propCity = propData.value.propData.city
        optionsFiltro.value.propState = propData.value.propData.state
        optionsFiltro.value.propZipCode = propData.value.propData.zip
        optionsFiltro.value.market = propData.value.propData.market
        optionsFiltro.value.propertyType = propData.value.propData.compPropertyType
        optionsFiltro.value.status = 'Closed'
        optionsFiltro.value.latitude = propData.value.propData.latitude
        optionsFiltro.value.longitude = propData.value.propData.longitude

        const arrayFilter3 = propData.value.propData.filterWhitin
        const withinMin = arrayFilter3[0]
        const withinMax = arrayFilter3[1]
        const arrayFilter2 = propData.value.propData.filterYear
        let yearMin = arrayFilter2[0]
        let yearMax = arrayFilter2[1]
        const arrayFilter1 = propData.value.propData.filterSqrt
        let squareMin = arrayFilter1[0]
        let squareMax = arrayFilter1[1]
        if (squareMax === 3000) {
          squareMax = 5000
        }
        const arrayFilter4 = propData.value.propData.filterBeds
        let bedsMin = arrayFilter4[0]
        let bedsMax = arrayFilter4[1]
        const arrayFilter5 = propData.value.propData.filterBaths
        let bathsMin = arrayFilter5[0]
        let bathsMax = arrayFilter5[1]
        const arrayFilter6 = propData.value.propData.filterSoldwhitin
        const soldwithinMin = arrayFilter6[0]
        const soldwithinMax = arrayFilter6[1]

        if (disablefilteryear.value) {
          yearMin = 0
          yearMax = 0
        }

        if (disablefiltersqrt.value) {
          squareMin = 0
          squareMax = 0
        }

        if (disablefilterbeds.value) {
          bedsMin = 0
          bedsMax = 0
        }

        if (disablefilterbaths.value) {
          bathsMin = 0
          bathsMax = 0
        }

        optionsFiltro.value.withinMin = withinMin
        optionsFiltro.value.withinMax = withinMax
        optionsFiltro.value.yearMin = yearMin
        optionsFiltro.value.yearMax = yearMax
        optionsFiltro.value.squareMin = squareMin
        optionsFiltro.value.squareMax = squareMax
        optionsFiltro.value.bedsMin = bedsMin
        optionsFiltro.value.bedsMax = bedsMax
        optionsFiltro.value.bathsMin = bathsMin
        optionsFiltro.value.bathsMax = bathsMax
        optionsFiltro.value.soldwithinMin = soldwithinMin
        optionsFiltro.value.soldwithinMax = soldwithinMax

        // En cualquier caso removemos los marcadores.
        markers.value = markers.value.filter(item => !(item.tipo === 'sold'))

        store
          .dispatch('app-invoice/fetchCheckProperties', {
            options: optionsFiltro.value,
          })
          .then(response => {
            propData.value.propData.filterSoldProperties = response.data
            // eslint-disable-next-line no-restricted-syntax
            for (const property of propData.value.propData.filterSoldProperties) {
              property.id = property.propertyId
              property.propertySqrFeetValue = propData.value.propData.propertySqrFeet - property.propertySqrFeet
              property.propertyYearDiff = propData.value.propData.propertyYearBuilt - property.propertyYearBuilt
              property.priceVariationPercent = property.priceVariation === property.listPrice ? 0.0 : ((property.listPrice / property.priceVariation) * 100 - 100).toFixed(1)
              const iconmarker = property.isSoldSale ? iconSale : iconSold
              property.isSelected = true

              const marker2 = {
                id: property.propertyId, lat: property.latitude, lon: property.longitude, address: property.address, icon: iconmarker, tipo: 'sold',
              }

              markers.value.push(marker2)
            }

            filterChanged.value = false
            propData.value.propData.filterListProperties = propData.value.propData.filterSoldProperties
            onCalculateComps()
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        propData.value.propData.filterListProperties = propData.value.propData.filterSoldProperties
      }
    }

    const onCheckSaleComps = () => {
      listpresented.value = 2
      if (filterChanged.value || !propData.value.propData.filterSaleProperties.length) {
        optionsFiltro.value.propertyId = propData.value.propData.propertyId
        optionsFiltro.value.propAddress = propData.value.propData.address
        optionsFiltro.value.propCity = propData.value.propData.city
        optionsFiltro.value.propState = propData.value.propData.state
        optionsFiltro.value.propZipCode = propData.value.propData.zip
        optionsFiltro.value.market = propData.value.propData.market
        optionsFiltro.value.propertyType = propData.value.propData.compPropertyType
        optionsFiltro.value.status = 'Active'
        optionsFiltro.value.latitude = propData.value.propData.latitude
        optionsFiltro.value.longitude = propData.value.propData.longitude

        const arrayFilter3 = propData.value.propData.filterWhitin
        const withinMin = arrayFilter3[0]
        const withinMax = arrayFilter3[1]
        const arrayFilter2 = propData.value.propData.filterYear
        let yearMin = arrayFilter2[0]
        let yearMax = arrayFilter2[1]
        const arrayFilter1 = propData.value.propData.filterSqrt
        let squareMin = arrayFilter1[0]
        let squareMax = arrayFilter1[1]
        if (squareMax === 3000) {
          squareMax = 5000
        }
        const arrayFilter4 = propData.value.propData.filterBeds
        let bedsMin = arrayFilter4[0]
        let bedsMax = arrayFilter4[1]
        const arrayFilter5 = propData.value.propData.filterBaths
        let bathsMin = arrayFilter5[0]
        let bathsMax = arrayFilter5[1]
        const arrayFilter6 = propData.value.propData.filterSoldwhitin
        const soldwithinMin = arrayFilter6[0]
        const soldwithinMax = arrayFilter6[1]

        if (disablefilteryear.value) {
          yearMin = 0
          yearMax = 0
        }

        if (disablefiltersqrt.value) {
          squareMin = 0
          squareMax = 0
        }

        if (disablefilterbeds.value) {
          bedsMin = 0
          bedsMax = 0
        }

        if (disablefilterbaths.value) {
          bathsMin = 0
          bathsMax = 0
        }

        optionsFiltro.value.withinMin = withinMin
        optionsFiltro.value.withinMax = withinMax
        optionsFiltro.value.yearMin = yearMin
        optionsFiltro.value.yearMax = yearMax
        optionsFiltro.value.squareMin = squareMin
        optionsFiltro.value.squareMax = squareMax
        optionsFiltro.value.bedsMin = bedsMin
        optionsFiltro.value.bedsMax = bedsMax
        optionsFiltro.value.bathsMin = bathsMin
        optionsFiltro.value.bathsMax = bathsMax
        optionsFiltro.value.soldwithinMin = soldwithinMin
        optionsFiltro.value.soldwithinMax = soldwithinMax

        // En cualquier caso removemos los marcadores de sale.
        markers.value = markers.value.filter(item => !(item.tipo === 'sale'))

        store
          .dispatch('app-invoice/fetchCheckProperties', {
            options: optionsFiltro.value,
          })
          .then(response => {
            propData.value.propData.filterSaleProperties = response.data
            // eslint-disable-next-line no-restricted-syntax
            for (const property of propData.value.propData.filterSaleProperties) {
              property.id = property.propertyId
              property.propertySqrFeetValue = propData.value.propData.propertySqrFeet - property.propertySqrFeet
              property.propertyYearDiff = propData.value.propData.propertyYearBuilt - property.propertyYearBuilt
              property.priceVariationPercent = property.priceVariation === property.listPrice ? 0.0 : (((property.priceVariation / property.listPrice) * 100 - 100) * -1).toFixed(1)
              const iconmarker = property.isSoldSale ? iconSale : iconSold
              property.isSelected = true

              const marker2 = {
                id: property.propertyId, lat: property.latitude, lon: property.longitude, address: property.address, icon: iconmarker, tipo: 'sale',
              }

              markers.value.push(marker2)
            }

            propData.value.propData.filterListProperties = propData.value.propData.filterSaleProperties
            filterChanged.value = false
            onCalculateComps()
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        propData.value.propData.filterListProperties = propData.value.propData.filterSaleProperties
      }
    }

    const onRefreshDataCompsv2 = async () => {
      listpresented.value = 0
      filterChanged.value = true

      if (filterChanged.value || !propData.value.propData.filterSoldProperties.length) {
        optionsFiltro.value.propertyId = propData.value.propData.propertyId
        optionsFiltro.value.propAddress = propData.value.propData.address
        optionsFiltro.value.propCity = propData.value.propData.city
        optionsFiltro.value.propState = propData.value.propData.state
        optionsFiltro.value.propZipCode = propData.value.propData.zip
        optionsFiltro.value.market = propData.value.propData.market
        optionsFiltro.value.propertyType = propData.value.propData.compPropertyType
        optionsFiltro.value.status = 'Closed'
        optionsFiltro.value.latitude = propData.value.propData.latitude
        optionsFiltro.value.longitude = propData.value.propData.longitude

        const arrayFilter3 = propData.value.propData.filterWhitin
        const withinMin = arrayFilter3[0]
        const withinMax = arrayFilter3[1]
        const arrayFilter2 = propData.value.propData.filterYear
        let yearMin = arrayFilter2[0]
        let yearMax = arrayFilter2[1]
        const arrayFilter1 = propData.value.propData.filterSqrt
        let squareMin = arrayFilter1[0]
        let squareMax = arrayFilter1[1]
        if (squareMax === 3000) {
          squareMax = 5000
        }
        const arrayFilter4 = propData.value.propData.filterBeds
        let bedsMin = arrayFilter4[0]
        let bedsMax = arrayFilter4[1]
        const arrayFilter5 = propData.value.propData.filterBaths
        let bathsMin = arrayFilter5[0]
        let bathsMax = arrayFilter5[1]

        const arrayFilter6 = propData.value.propData.filterSoldwhitin
        const soldwithinMin = arrayFilter6[0]
        const soldwithinMax = arrayFilter6[1]

        if (disablefilteryear.value) {
          yearMin = 0
          yearMax = 0
        }

        if (disablefiltersqrt.value) {
          squareMin = 0
          squareMax = 0
        }

        if (disablefilterbeds.value) {
          bedsMin = 0
          bedsMax = 0
        }

        if (disablefilterbaths.value) {
          bathsMin = 0
          bathsMax = 0
        }

        optionsFiltro.value.withinMin = withinMin
        optionsFiltro.value.withinMax = withinMax
        optionsFiltro.value.yearMin = yearMin
        optionsFiltro.value.yearMax = yearMax
        optionsFiltro.value.squareMin = squareMin
        optionsFiltro.value.squareMax = squareMax
        optionsFiltro.value.bedsMin = bedsMin
        optionsFiltro.value.bedsMax = bedsMax
        optionsFiltro.value.bathsMin = bathsMin
        optionsFiltro.value.bathsMax = bathsMax
        optionsFiltro.value.soldwithinMin = soldwithinMin
        optionsFiltro.value.soldwithinMax = soldwithinMax

        // En cualquier caso removemos los marcadores.
        markers.value = markers.value.filter(item => !(item.tipo === 'sold'))

        const url = `${process.env.VUE_APP_API_URL}/api/propertiescatalog/leerlistageo`

        const { status, data } = await axios.get(url, {
          params: {
            options: optionsFiltro.value,
          },
        })

        if (status === 200) {
          propData.value.propData.filterSoldProperties = data
          // eslint-disable-next-line no-restricted-syntax
          for (const property of propData.value.propData.filterSoldProperties) {
            property.id = property.propertyId
            property.propertySqrFeetValue = propData.value.propData.propertySqrFeet - property.propertySqrFeet
            property.propertyYearDiff = propData.value.propData.propertyYearBuilt - property.propertyYearBuilt
            property.priceVariationPercent = property.priceVariation === property.listPrice ? 0.0 : ((property.listPrice / property.priceVariation) * 100 - 100).toFixed(1)
            const iconmarker = property.isSoldSale ? iconSale : iconSold
            property.isSelected = true

            const marker2 = {
              id: property.propertyId, lat: property.latitude, lon: property.longitude, address: property.address, icon: iconmarker, tipo: 'sold',
            }

            markers.value.push(marker2)
          }

          console.log('Sold List')
          onCalculateComps()
        }
      }

      filterChanged.value = true
      if (filterChanged.value || !propData.value.propData.filterSaleProperties.length) {
        optionsFiltro.value.propertyId = propData.value.propData.propertyId
        optionsFiltro.value.propAddress = propData.value.propData.address
        optionsFiltro.value.propCity = propData.value.propData.city
        optionsFiltro.value.propState = propData.value.propData.state
        optionsFiltro.value.propZipCode = propData.value.propData.zip
        optionsFiltro.value.market = propData.value.propData.market
        optionsFiltro.value.propertyType = propData.value.propData.compPropertyType
        optionsFiltro.value.status = 'Active'
        optionsFiltro.value.latitude = propData.value.propData.latitude
        optionsFiltro.value.longitude = propData.value.propData.longitude

        const arrayFilter3 = propData.value.propData.filterWhitin
        const withinMin = arrayFilter3[0]
        const withinMax = arrayFilter3[1]
        const arrayFilter2 = propData.value.propData.filterYear
        let yearMin = arrayFilter2[0]
        let yearMax = arrayFilter2[1]
        const arrayFilter1 = propData.value.propData.filterSqrt
        let squareMin = arrayFilter1[0]
        let squareMax = arrayFilter1[1]
        if (squareMax === 3000) {
          squareMax = 5000
        }
        const arrayFilter4 = propData.value.propData.filterBeds
        let bedsMin = arrayFilter4[0]
        let bedsMax = arrayFilter4[1]
        const arrayFilter5 = propData.value.propData.filterBaths
        let bathsMin = arrayFilter5[0]
        let bathsMax = arrayFilter5[1]
        const arrayFilter6 = propData.value.propData.filterSoldwhitin
        const soldwithinMin = arrayFilter6[0]
        const soldwithinMax = arrayFilter6[1]

        if (disablefilteryear.value) {
          yearMin = 0
          yearMax = 0
        }

        if (disablefiltersqrt.value) {
          squareMin = 0
          squareMax = 0
        }

        if (disablefilterbeds.value) {
          bedsMin = 0
          bedsMax = 0
        }

        if (disablefilterbaths.value) {
          bathsMin = 0
          bathsMax = 0
        }

        optionsFiltro.value.withinMin = withinMin
        optionsFiltro.value.withinMax = withinMax
        optionsFiltro.value.yearMin = yearMin
        optionsFiltro.value.yearMax = yearMax
        optionsFiltro.value.squareMin = squareMin
        optionsFiltro.value.squareMax = squareMax
        optionsFiltro.value.bedsMin = bedsMin
        optionsFiltro.value.bedsMax = bedsMax
        optionsFiltro.value.bathsMin = bathsMin
        optionsFiltro.value.bathsMax = bathsMax
        optionsFiltro.value.soldwithinMin = soldwithinMin
        optionsFiltro.value.soldwithinMax = soldwithinMax

        // En cualquier caso removemos los marcadores.
        markers.value = markers.value.filter(item => !(item.tipo === 'sale'))

        const url = `${process.env.VUE_APP_API_URL}/api/propertiescatalog/leerlistageo`

        const { status, data } = await axios.get(url, {
          params: {
            options: optionsFiltro.value,
          },
        })

        if (status === 200) {
          propData.value.propData.filterSaleProperties = data

          // eslint-disable-next-line no-restricted-syntax
          for (const property of propData.value.propData.filterSaleProperties) {
            property.id = property.propertyId
            property.propertySqrFeetValue = propData.value.propData.propertySqrFeet - property.propertySqrFeet
            property.propertyYearDiff = propData.value.propData.propertyYearBuilt - property.propertyYearBuilt
            property.priceVariationPercent = property.priceVariation === property.listPrice ? 0.0 : (((property.priceVariation / property.listPrice) * 100 - 100) * -1).toFixed(1)
            const iconmarker = property.isSoldSale ? iconSale : iconSold
            property.isSelected = true

            const marker2 = {
              id: property.propertyId, lat: property.latitude, lon: property.longitude, address: property.address, icon: iconmarker, tipo: 'sale',
            }

            markers.value.push(marker2)
          }

          console.log('Sale List')
          onCalculateComps()
        }
      }

      filterChanged.value = false
      console.log('Refresh Data')

      const filterSold = propData.value.propData.filterSoldProperties.filter(obj => obj.isSelected === true)
      const filterSale = propData.value.propData.filterSaleProperties.filter(obj => obj.isSelected === true)

      propData.value.propData.filterListProperties = [...filterSold, ...filterSale]
      propData.value.propData.filterListProperties.sort((a, b) => ((a.distance > b.distance) ? 1 : -1))
      onCalculateComps()
    }

    return {
      propData,
      provider,
      zoom,
      checkedrows,
      disablefilteryear,
      disablefiltersqrt,
      disablefilterbeds,
      disablefilterbaths,
      center,
      markers,
      iconSale,
      iconSold,
      onCheckSoldComps,
      onCheckSaleComps,
      onCheckSelectedComps,
      onChangeFilter,
      onDisableFilterYear,
      onDisableFilterSqrt,
      onDisableFilterBeds,
      onDisableFilterBaths,
      onCalculateComps,
      onRefreshDataCompsv2,
      listaComparaciones,
      listpresented,

      // Icons
      icons: {
        mdiSendOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';
</style>
