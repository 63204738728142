<template>
  <div>
    <v-toolbar
      class="primary"
      short
    >
      <v-toolbar-title style="color:white;">
        Add/Edit Values of the Comparison
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card
      v-if="data.propData"
      class="app-invoice-editable"
    >
      <v-card-actions>
        <v-btn
          color="primary"
          small
          @click="onSubmit"
        >
          Save Record
        </v-btn>
        <v-btn
          color="secondary"
          small
          outlined
          @click="regresarListado"
        >
          Return to List
        </v-btn>
      </v-card-actions>
      <v-card-text>
        <v-form ref="form">
          <v-tabs v-model="tab">
            <v-tab>
              Comparison Information
            </v-tab>
            <!-- Property Details -->
            <v-tab-item>
              <v-container>
                <v-row
                  ref="form"
                  dense
                >
                  <!-- Property Data -->
                  <v-col
                    cols="12"
                    md="9"
                  >
                    <v-form>
                      <v-row dense>
                        <v-col
                          cols="12"
                          md="3"
                        >
                          <label>Market:</label>
                        </v-col>

                        <v-col
                          cols="12"
                          md="8"
                        >
                          <v-select
                            v-model="data.propData.market"
                            outlined
                            :menu-props="{ offsetY: true }"
                            :items="marketsLista"
                            dense
                            item-text="marketShortName"
                            item-value="marketShortName"
                            placeholder="Select Market"
                            autocomplete="off"
                            class="select-invoice-to"
                            @change="$emit('onChangeMarket', {$event})"
                          ></v-select>
                        </v-col>

                        <v-col
                          cols="12"
                          md="3"
                        >
                          <label>Property Address:</label>
                        </v-col>

                        <v-col
                          cols="12"
                          md="9"
                        >
                          <v-text-field
                            v-model="data.propData.address"
                            outlined
                            dense
                            autocomplete="off"
                            placeholder="Property Address"
                            hide-details
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="3"
                        >
                          <label>Property City, State, Zip:</label>
                        </v-col>

                        <v-col
                          cols="12"
                          md="4"
                        >
                          <v-text-field
                            v-model="data.propData.city"
                            outlined
                            dense
                            autocomplete="off"
                            placeholder="Property City"
                            hide-details
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="3"
                        >
                          <v-text-field
                            v-model="data.propData.state"
                            outlined
                            dense
                            autocomplete="off"
                            placeholder="Property State"
                            hide-details
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="2"
                        >
                          <v-text-field
                            v-model="data.propData.zip"
                            outlined
                            dense
                            autocomplete="off"
                            placeholder="Zip Code"
                            hide-details
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="3"
                        >
                          <label
                            dense
                            offset-y
                            hide-details
                          >
                            Property Details:
                          </label>
                        </v-col>

                        <v-col
                          cols="12"
                          md="3"
                        >
                          <v-text-field
                            v-model="data.propData.propertyBeds"
                            outlined
                            dense
                            autocomplete="off"
                            label="Bedrooms"
                            placeholder="Bedrooms"
                            hide-details
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="3"
                        >
                          <v-text-field
                            v-model="data.propData.propertyBaths"
                            outlined
                            dense
                            autocomplete="off"
                            label="Baths"
                            placeholder="Baths"
                            hide-details
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="3"
                        >
                          <v-text-field
                            v-model="data.propData.propertySqrFeet"
                            outlined
                            dense
                            autocomplete="off"
                            label="Sq. Ft."
                            placeholder="Sq. Feet"
                            hide-details
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="3"
                        >
                        </v-col>

                        <v-col
                          cols="12"
                          md="2"
                        >
                          <v-text-field
                            v-model="data.propData.propertyYearBuilt"
                            outlined
                            dense
                            autocomplete="off"
                            label="Year"
                            placeholder="Year"
                            hide-details
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="2"
                        >
                          <v-text-field
                            v-model="data.propData.propertyLotSize"
                            outlined
                            dense
                            autocomplete="off"
                            label="Lot Size"
                            placeholder="Lot Size"
                            hide-details
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="2"
                        >
                          <v-text-field
                            v-model="data.propData.listPrice"
                            outlined
                            dense
                            autocomplete="off"
                            label="List Price"
                            placeholder="List Price"
                            hide-details
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="2"
                        >
                          <v-text-field
                            v-model="data.propData.marketEstimate"
                            outlined
                            dense
                            autocomplete="off"
                            label="Market Value"
                            placeholder="Market Value"
                            hide-details
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="3"
                        >
                          <label>Notes:</label>
                        </v-col>

                        <v-col
                          cols="12"
                          md="9"
                        >
                          <v-textarea
                            v-model="data.propData.propertyNotes"
                            :rules="rulesLength"
                            counter
                            outlined
                            dense
                            rows="3"
                            placeholder="(120 character limit)"
                            autocomplete="off"
                            hide-details="auto"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-col>
                  <!-- Property Data Right Area-->
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-form>
                      <v-row dense>
                        <v-col
                          cols="12"
                          md="9"
                        >
                          <v-select
                            v-model="data.propData.compGarage"
                            :items="SelectValues"
                            outlined
                            dense
                            label="Garage Door"
                            class="select-invoice-to"
                            hide-details
                          >
                          </v-select>
                        </v-col>
                        <v-col
                          cols="12"
                          md="9"
                        >
                          <v-select
                            v-model="data.propData.compCarport"
                            :items="SelectValues"
                            outlined
                            dense
                            label="Carport"
                            class="select-invoice-to"
                            hide-details
                          >
                          </v-select>
                        </v-col>
                        <v-col
                          cols="12"
                          md="9"
                        >
                          <v-select
                            v-model="data.propData.compHoa"
                            :items="SelectValues"
                            outlined
                            dense
                            label="HOA"
                            class="select-invoice-to"
                            hide-details
                          >
                          </v-select>
                        </v-col>
                        <v-col
                          cols="12"
                          md="9"
                        >
                          <v-checkbox
                            v-model="data.propData.compWaterfront"
                            dense
                            label="Waterfront"
                            hide-details
                          ></v-checkbox>
                        </v-col>
                        <v-col
                          cols="12"
                          md="9"
                        >
                          <v-checkbox
                            v-model="data.propData.compHasview"
                            dense
                            label="Has View"
                            hide-details
                          ></v-checkbox>
                        </v-col>
                        <v-col
                          cols="12"
                          md="9"
                        >
                          <v-checkbox
                            v-model="data.propData.compHaspool"
                            dense
                            label="Has Pool"
                            hide-details
                          ></v-checkbox>
                        </v-col>
                        <v-col
                          cols="12"
                          md="9"
                        >
                          <v-checkbox
                            v-model="data.propData.compRv"
                            dense
                            label="RV Parking"
                            hide-details
                          ></v-checkbox>
                        </v-col>
                        <v-col
                          cols="12"
                          md="10"
                        >
                          <v-checkbox
                            v-model="data.propData.compGuesthouse"
                            dense
                            label="Guest House"
                            hide-details
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-col>

                  <v-col
                    cols="12"
                    md="11"
                  >
                    <v-btn
                      v-if="!data.propData.id"
                      depressed
                      color="primary"
                      @click="$emit('onCheckProperty02')"
                    >
                      Obtain Data Property
                    </v-btn>
                  </v-col>

                  <!-- Zone Map -->
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <l-map
                      :zoom="zoom"
                      :center="center"
                      :options="mapOptions"
                      style="height: 400px; width: 100%; position:relative"
                    >
                      <l-tile-layer
                        v-for="tileProvider in tileProviders"
                        :key="tileProvider.name"
                        :name="tileProvider.name"
                        :visible="tileProvider.visible"
                        :url="tileProvider.url"
                        :attribution="tileProvider.attribution"
                        layer-type="base"
                      />
                      <l-control-attribution
                        position="topright"
                        :prefix="attributionPrefix"
                        class="custom-control-watermark"
                      />
                      <l-control-scale
                        :imperial="imperial"
                        position="bottomright"
                      />
                      <l-marker
                        v-for="(marker, index) in markers"
                        :key="index"
                        :draggable="true"
                        :lat-lng="[marker.lat, marker.lon]"
                        :icon="marker.icon"
                      >
                        <l-tooltip>
                          {{ marker.address }}
                        </l-tooltip>
                      </l-marker>
                    </l-map>
                  </v-col>
                  <!-- Buttons to Show Filtered Data -->
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-btn
                      color="error"
                      small
                      @click="$emit('onCheckSoldComps')"
                    >
                      Sold Comps
                    </v-btn>
                    <v-btn
                      color="success"
                      small
                      @click="$emit('onCheckSaleComps')"
                    >
                      For Sale Comps
                    </v-btn>
                    <v-btn
                      color="info"
                      small
                      @click="$emit('onCheckSelectedComps')"
                    >
                      Selected Comps
                    </v-btn>
                    <v-btn
                      color="warning"
                      small
                      @click="$emit('onRefreshDataComps')"
                    >
                      Refresh Data
                    </v-btn>
                  </v-col>
                  <!-- Table Data Auxiliar -->
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-data-table
                      :headers="tableCompsColumns"
                      :items="listacomparaciones"
                      class="my-class"
                      hide-default-footer
                      dense
                    >
                      <template #[`item.legendText`]="{item}">
                        <v-chip
                          x-small
                          :color="obligatorioColor[item.legendText === 'FOR SALE COMPS']"
                          :class="`${obligatorioColor[item.legendText === 'FOR SALE COMPS']}--text`"
                          class="v-chip-light-bg"
                        >
                          {{ item.legendText }}
                        </v-chip>
                      </template>
                      <template #[`item.listPriceAverage`]="{item}">
                        {{ item.listPriceAverage | format_number }}
                      </template>
                      <template #[`item.sqrFtPriceAverage`]="{item}">
                        {{ item.sqrFtPriceAverage | format_number }}
                      </template>
                      <template #[`item.arvPriceAverage`]="{item}">
                        {{ item.arvPriceAverage | format_number }}
                      </template>
                    </v-data-table>
                    <v-row dense>
                      <v-col
                        cols="6"
                        md="2"
                      >
                        <v-text-field
                          v-model="data.propData.estimateRedfin"
                          outlined
                          dense
                          autocomplete="off"
                          label="Est. Redfin"
                          placeholder=""
                          style="font-size: 11px; font-weight: bolder; color: black;"
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="6"
                        md="2"
                      >
                        <v-text-field
                          v-model="data.propData.estimateZillow"
                          outlined
                          dense
                          autocomplete="off"
                          label="Est. Zillow"
                          placeholder="Est. Zillow"
                          style="font-size: 11px; font-weight: bolder; color: black;"
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="6"
                        md="2"
                      >
                        <v-text-field
                          v-model="data.propData.estimateRealtor01"
                          outlined
                          dense
                          autocomplete="off"
                          label="Quantarium"
                          placeholder="Est. Realtor01"
                          style="font-size: 11px; font-weight: bolder; color: black;"
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="6"
                        md="2"
                      >
                        <v-text-field
                          v-model="data.propData.estimateRealtor02"
                          outlined
                          dense
                          autocomplete="off"
                          label="CoreLogic®"
                          placeholder="Est. Realtor02"
                          style="font-size: 11px; font-weight: bolder; color: black;"
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="6"
                        md="2"
                      >
                        <v-text-field
                          v-model="data.propData.estimateRealtor03"
                          outlined
                          dense
                          autocomplete="off"
                          label="Collateral Analytics"
                          placeholder="Est. Realtor03"
                          style="font-size: 11px; font-weight: bolder; color: black;"
                          hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col
                    cols="12"
                    md="11"
                  >
                    <h4>Refine Filter</h4>
                  </v-col>
                  <!-- Filter PropertyType -->
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-select
                      v-model="data.propData.compPropertyType"
                      :items="typesList"
                      item-text="propertytype"
                      item-value="propertytype"
                      label="Property Type"
                      outlined
                      dense
                      autocomplete="off"
                      offset-y
                      class="select-invoice-to"
                      @change="$emit('onChangeFilter')"
                    >
                    </v-select>
                  </v-col>

                  <v-spacer></v-spacer>
                  <!-- Filter Sqrt Size -->
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-row>
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <v-icon
                            size="18"
                            class="me-1"
                            dense
                            small
                            v-on="on"
                            @click="$emit('onDisableFilterSqrt')"
                          >
                            {{ icons.mdiClose }}
                          </v-icon>
                        </template>
                        <span>Filter Not Applicable</span>
                      </v-tooltip>
                      <v-range-slider
                        v-model="data.propData.filterSqrt"
                        :max="data.propData.filterSqrtMax"
                        :min="data.propData.filterSqrtMin"
                        :tick-labels="SqftLabel"
                        dense
                        step="10"
                        :disabled="disablefiltersqrt"
                        style="font-size: 11px; color: black;"
                        @change="$emit('onChangeFilter')"
                      >
                        <template v-slot:prepend>
                          <label
                            class="mt-0 pt-0"
                            hide-details
                            @change="$set(data.propData.filterSqrt, 0, $event)"
                          >
                            {{
                              data.propData.filterSqrt[0]
                            }}
                          </label>
                        </template>
                        <template v-slot:append>
                          <label
                            class="mt-0 pt-0"
                            hide-details
                            @change="$set(data.propData.filterSqrt, 0, $event)"
                          >
                            {{
                              data.propData.filterSqrt[1]
                            }}
                          </label>
                        </template>
                      </v-range-slider>
                    </v-row>
                  </v-col>

                  <v-spacer></v-spacer>
                  <!-- Filter Year -->
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-row>
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <v-icon
                            size="18"
                            class="me-1"
                            dense
                            small
                            v-on="on"
                            @click="$emit('onDisableFilterYear')"
                          >
                            {{ icons.mdiClose }}
                          </v-icon>
                        </template>
                        <span>Filter Not Applicable</span>
                      </v-tooltip>
                      <v-range-slider
                        v-model="data.propData.filterYear"
                        :max="data.propData.filterYearMax"
                        :min="data.propData.filterYearMin"
                        :tick-labels="YearLabel"
                        dense
                        step="1"
                        :disabled="disablefilteryear"
                        style="font-size: 11px; color: black;"
                        @change="$emit('onChangeFilter')"
                      >
                        <template v-slot:prepend>
                          <label
                            class="mt-0 pt-0"
                            hide-details
                            @change="$set(data.propData.filterYear, 0, $event)"
                          >
                            {{
                              data.propData.filterYear[0]
                            }}
                          </label>
                        </template>
                        <template v-slot:append>
                          <label
                            class="mt-0 pt-0"
                            hide-details
                            @change="$set(data.propData.filterYear, 0, $event)"
                          >
                            {{
                              data.propData.filterYear[1]
                            }}
                          </label>
                        </template>
                      </v-range-slider>
                    </v-row>
                  </v-col>
                  <!-- Filter Miles Whitin -->
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-range-slider
                      v-model="data.propData.filterWhitin"
                      :max="data.propData.filterWhitinMax"
                      :min="data.propData.filterWhitinMin"
                      :tick-labels="WithinLabel"
                      dense
                      step="0.1"
                      style="font-size: 11px; color: black;"
                      @change="$emit('onChangeFilter')"
                    >
                      <template v-slot:prepend>
                        <label
                          class="mt-0 pt-0"
                          hide-details
                          @change="$set(data.propData.filterWhitin, 0, $event)"
                        >
                          {{
                            data.propData.filterWhitin[0]
                          }}
                        </label>
                      </template>
                      <template v-slot:append>
                        <label
                          class="mt-0 pt-0"
                          hide-details
                          @change="$set(data.propData.filterWhitin, 0, $event)"
                        >
                          {{
                            data.propData.filterWhitin[1]
                          }}
                        </label>
                      </template>
                    </v-range-slider>
                  </v-col>

                  <v-spacer></v-spacer>
                  <!-- Filter Beds -->
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-row>
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <v-icon
                            size="18"
                            class="me-1"
                            dense
                            small
                            v-on="on"
                            @click="$emit('onDisableFilterBeds')"
                          >
                            {{ icons.mdiClose }}
                          </v-icon>
                        </template>
                        <span>Filter Not Applicable</span>
                      </v-tooltip>
                      <v-range-slider
                        v-model="data.propData.filterBeds"
                        :max="data.propData.filterBedsMax"
                        :min="data.propData.filterBedsMin"
                        :tick-labels="BedsLabel"
                        dense
                        step="1"
                        :disabled="disablefilterbeds"
                        style="font-size: 11px; color: black;"
                        @change="$emit('onChangeFilter')"
                      >
                        <template v-slot:prepend>
                          <label
                            class="mt-0 pt-0"
                            hide-details
                            @change="$set(data.propData.filterBeds, 0, $event)"
                          >
                            {{
                              data.propData.filterBeds[0]
                            }}
                          </label>
                        </template>
                        <template v-slot:append>
                          <label
                            class="mt-0 pt-0"
                            hide-details
                            @change="$set(data.propData.filterBeds, 0, $event)"
                          >
                            {{
                              data.propData.filterBeds[1]
                            }}
                          </label>
                        </template>
                      </v-range-slider>
                    </v-row>
                  </v-col>

                  <v-spacer></v-spacer>
                  <!-- Filter Baths -->
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-row>
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <v-icon
                            size="18"
                            class="me-1"
                            dense
                            small
                            v-on="on"
                            @click="$emit('onDisableFilterBaths')"
                          >
                            {{ icons.mdiClose }}
                          </v-icon>
                        </template>
                        <span>Filter Not Applicable</span>
                      </v-tooltip>
                      <v-range-slider
                        v-model="data.propData.filterBaths"
                        :max="data.propData.filterBathsMax"
                        :min="data.propData.filterBathsMin"
                        :tick-labels="BathsLabel"
                        dense
                        step="0.5"
                        :disabled="disablefilterbaths"
                        style="font-size: 11px; color: black;"
                        @change="$emit('onChangeFilter')"
                      >
                        <template v-slot:prepend>
                          <label
                            class="mt-0 pt-0"
                            hide-details
                            @change="$set(data.propData.filterBaths, 0, $event)"
                          >
                            {{
                              data.propData.filterBaths[0]
                            }}
                          </label>
                        </template>
                        <template v-slot:append>
                          <label
                            class="mt-0 pt-0"
                            hide-details
                            @change="$set(data.propData.filterBaths, 0, $event)"
                          >
                            {{
                              data.propData.filterBaths[1]
                            }}
                          </label>
                        </template>
                      </v-range-slider>
                    </v-row>
                  </v-col>

                  <v-spacer></v-spacer>
                  <!-- Filter Sold Whitin -->
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-range-slider
                      v-model="data.propData.filterSoldwhitin"
                      :max="data.propData.filterSoldwhitinMax"
                      :min="data.propData.filterSoldwhitinMin"
                      :tick-labels="SoldWithinLabel"
                      dense
                      step="1"
                      style="font-size: 11px; color: black;"
                      @change="$emit('onChangeFilter')"
                    >
                      <template v-slot:prepend>
                        <label
                          class="mt-0 pt-0"
                          hide-details
                          @change="$set(data.propData.filterSoldwhitin, 0, $event)"
                        >
                          {{
                            data.propData.filterSoldwhitin[0]
                          }}
                        </label>
                      </template>
                      <template v-slot:append>
                        <label
                          class="mt-0 pt-0"
                          hide-details
                          @change="$set(data.propData.filterSoldwhitin, 0, $event)"
                        >
                          {{
                            data.propData.filterSoldwhitin[1]
                          }}
                        </label>
                      </template>
                    </v-range-slider>
                  </v-col>

                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-data-table
                      :headers="tablePropsColumns"
                      :items="data.propData.filterListProperties"
                      :options.sync="options"
                      :footer-props="{'items-per-page-options': [], 'items-per-page-text': null, 'disable-items-per-page': true}"
                      class="my-class"
                      dense
                    >
                      <template v-slot:[`item.controls`]="props">
                        <v-tooltip bottom>
                          <template #activator="{ on }">
                            <v-btn
                              class="mx-2"
                              fab
                              x-small
                              @click="$emit('onWebViewDetailsClick',{props})"
                              v-on="on"
                            >
                              <v-icon
                                size="20"
                                color="success"
                              >
                                {{ icons.mdiLink }}
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>View Details</span>
                        </v-tooltip>
                      </template>
                      <template v-slot:[`header.isSelected`]>
                        <v-checkbox
                          v-model="checkedrows"
                          dense
                          small
                          color="success"
                          @change="$emit('onChangeSelectedRows',{checkedrows})"
                        ></v-checkbox>
                      </template>
                      <template #[`item.isSoldSale`]="{item}">
                        <v-chip
                          x-small
                          :color="obligatorioColor[item.isSoldSale]"
                          :class="`${obligatorioColor[item.isSoldSale]}--text`"
                          class="v-chip-light-bg"
                        >
                          {{ obliga[item.isSoldSale] }}
                        </v-chip>
                      </template>
                      <template #[`item.propertySqrFeetValue`]="{item}">
                        <v-chip
                          x-small
                          :color="obligatorioColor[item.propertySqrFeetValue > 0]"
                          :class="`${obligatorioColor[item.propertySqrFeetValue > 0]}--text`"
                          class="v-chip-light-bg"
                        >
                          {{ item.propertySqrFeetValue }}
                        </v-chip>
                      </template>
                      <template #[`item.propertyYearDiff`]="{item}">
                        <v-chip
                          x-small
                          :color="obligatorioColor[item.propertyYearDiff > 0]"
                          :class="`${obligatorioColor[item.propertyYearDiff > 0]}--text`"
                          class="v-chip-light-bg"
                        >
                          {{ item.propertyYearDiff }}
                        </v-chip>
                      </template>
                      <template #[`item.priceVariationPercent`]="{item}">
                        <v-chip
                          v-show="(item.priceVariationPercent) !== 0"
                          x-small
                          :color="obligatorioColor[item.priceVariationPercent > 0]"
                          :class="`${obligatorioColor[item.priceVariationPercent > 0]}--text`"
                          class="v-chip-light-bg"
                        >
                          {{ item.priceVariationPercent }}
                        </v-chip>
                      </template>
                      <template v-slot:[`item.isSelected`]="{item}">
                        <v-simple-checkbox
                          v-model="item.isSelected"
                          :ripple="false"
                          @input="$emit('onSelectedProperty',{item})"
                        ></v-simple-checkbox>
                      </template>
                      <template #[`item.onRedfinDate`]="{item}">
                        <span>{{ item.onRedfinDate | luxon("MM-dd-yyyy") }} </span>
                      </template>
                      <template #[`item.listPrice`]="{item}">
                        {{ item.listPrice | format_number }}
                      </template>
                      <template #[`item.propertySqrPrice`]="{item}">
                        {{ item.propertySqrPrice | round_number }}
                      </template>
                      <template #[`item.propertyDOM`]="{item}">
                        {{ item.propertyDOM | round_number }}
                      </template>
                      <template #[`item.propertyDTC`]="{item}">
                        {{ item.propertyDTC | round_number }}
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
              </v-container>
            </v-tab-item>
          </v-tabs>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
    </v-card>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import themeConfig from '@themeConfig'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

// css imports
import
{
  mdiDotsVertical,
  mdiClose,
  mdiCogOutline,
  mdiCloud,
  mdiScanner,
  mdiPrinter,
  mdiArchive,
  mdiFilePdf,
  mdiWebBox,
  mdiLink,
}
  from '@mdi/js'

import { icon } from 'leaflet'
import {
  LMap,
  LTileLayer,
  LControlAttribution,
  LControlScale,
  LMarker,
  LTooltip,
} from 'vue2-leaflet'
import propStoreModules from './propcompStore'
import useListasAdicionales from './useListasAdicionales'

export default {
  components: {
    LMap,
    LTileLayer,
    LControlAttribution,
    LControlScale,
    LMarker,
    LTooltip,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    zoom: {
      type: Number,
      required: true,
    },
    center: {
      type: Array,
      required: true,
    },
    markers: {
      type: Array,
      required: true,
    },
    listacomparaciones: {
      type: Array,
      required: true,
    },
    checkedrows: {
      type: Boolean,
      required: true,
    },
    disablefilteryear: {
      type: Boolean,
      required: true,
    },
    disablefiltersqrt: {
      type: Boolean,
      required: true,
    },
    disablefilterbeds: {
      type: Boolean,
      required: true,
    },
    disablefilterbaths: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    // ————————————————————————————————————
    //* ——— Store Registration
    // ————————————————————————————————————
    const tab = ref('')

    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    const propDataLocal = ref({})

    // Elementos de la Tabla
    const {
      tablePropsColumns, options, tableCompsColumns,
    } = useListasAdicionales()

    propDataLocal.value = JSON.parse(JSON.stringify(props.data))

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) {
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, propStoreModules)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const grabarInformacion = () => {
      propDataLocal.value = JSON.parse(JSON.stringify(props.data))
    }

    const regresarListado = () => {
      router.go(-1)
    }

    const marketsLista = ref([])
    store.dispatch('app-invoice/fetchMarkets').then(response => {
      marketsLista.value = response.data
    })

    const typesList = ref(['Single Family Residential', 'Multi Family Residential', 'Vacant Land'])

    // store.dispatch('app-invoice/fetchPropertiesTypes').then(response => {
    //   typesList.value = response.data
    // })

    const onSubmit = () => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      propDataLocal.value = JSON.parse(JSON.stringify(props.data))

      // console.log(propDataLocal.value.propData)

      propDataLocal.value.propData.usuarioRegistro = userData.email
      propDataLocal.value.propData.usuarioModifico = userData.email

      // ArrayValues
      const arrayFilter1 = propDataLocal.value.propData.filterSqrt
      const squareMin = arrayFilter1[0]
      const squareMax = arrayFilter1[1]
      const arrayFilter2 = propDataLocal.value.propData.filterYear
      const yearMin = arrayFilter2[0]
      const yearMax = arrayFilter2[1]
      const arrayFilter3 = propDataLocal.value.propData.filterWhitin
      const withinMin = arrayFilter3[0]
      const withinMax = arrayFilter3[1]
      const arrayFilter4 = propDataLocal.value.propData.filterBeds
      const bedsMin = arrayFilter4[0]
      const bedsMax = arrayFilter4[1]
      const arrayFilter5 = propDataLocal.value.propData.filterBaths
      const bathsMin = arrayFilter5[0]
      const bathsMax = arrayFilter5[1]
      const arrayFilter6 = propDataLocal.value.propData.filterSoldwhitin
      const soldwithinMin = arrayFilter6[0]
      const soldwithinMax = arrayFilter6[1]

      // Changes
      propDataLocal.value.propData.filterSqrtMin = squareMin
      propDataLocal.value.propData.filterSqrtMax = squareMax
      propDataLocal.value.propData.filterYearMin = yearMin
      propDataLocal.value.propData.filterYearMax = yearMax
      propDataLocal.value.propData.filterWhitinMin = withinMin
      propDataLocal.value.propData.filterWhitinMax = withinMax
      propDataLocal.value.propData.filterBedsMin = bedsMin
      propDataLocal.value.propData.filterBedsMax = bedsMax
      propDataLocal.value.propData.filterBathsMin = bathsMin
      propDataLocal.value.propData.filterBathsMax = bathsMax
      propDataLocal.value.propData.filterSoldwhitinMin = soldwithinMin
      propDataLocal.value.propData.filterSoldwhitinMax = soldwithinMax

      // Values to get data.propData.filterSqrt[0]
      // propDataLocal.value.propData.filter

      store.dispatch('app-invoice/addPropComparison', propDataLocal.value.propData).then(() => {
        Swal.fire({
          text: 'Comparison Added Successfully!',
          toast: true,
          position: 'top-right',
          iconColor: 'white',
          icon: 'success',
          customClass: {
            popup: 'colored-toast',
          },
          timer: 1500,
          timerProgressBar: true,
          showConfirmButton: false,
        })
        router.go(-1)
      })
    }

    const iconSale = icon({
      iconUrl: 'images/marker-icon-green.png',
      shadowUrl: 'images/marker-shadow.png',
      iconSize: [25, 41],
      iconAnchor: [20, 20],
      shadowSize: [41, 41],
      shadowAnchor: [20, 20],
    })

    const iconSold = icon({
      iconUrl: 'images/marker-icon-red.png',
      shadowUrl: 'images/marker-shadow.png',
      iconSize: [25, 41],
      iconAnchor: [20, 20],
      shadowSize: [41, 41],
      shadowAnchor: [20, 20],
    })

    const tileProviders = [
      {
        name: 'OpenStreetMap',
        visible: true,
        attribution: '',
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      },
    ]

    const obligatorioColor = {
      true: 'success',
      false: 'error',
    }

    return {
      tab,
      grabarInformacion,
      onSubmit,
      regresarListado,
      propDataLocal,
      tablePropsColumns,
      typesList,
      options,
      obligatorioColor,
      obliga: {
        true: 'Listed',
        false: 'Sold',
      },

      mapOptions: {
        zoomControl: true,

        // attributionControl: false,
        zoomSnap: 0.5,
      },
      tileProviders,
      attributionPrefix: 'Comparison Map',
      iconSale,
      iconSold,
      marketsLista,
      tableCompsColumns,

      imperial: false,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Icons
      icons: {
        mdiClose,
        mdiCogOutline,
        mdiDotsVertical,
        mdiCloud,
        mdiScanner,
        mdiPrinter,
        mdiArchive,
        mdiFilePdf,
        mdiWebBox,
        mdiLink,
      },

      //
      SqftLabel: [
        'Square Feet',
      ],
      YearLabel: [
        'Year Built',
      ],
      WithinLabel: [
        'Within (ml)',
      ],
      BedsLabel: [
        'Beds',
      ],
      BathsLabel: [
        'Baths',
      ],
      SoldWithinLabel: [
        'Sold Within (Months)',
      ],

      //
      SelectValues: [
        0, 1, 2, 3, 4, 5,
      ],

      //
      rulesLength: [v => v.length <= 120 || 'Max 120 characters'],
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';

.custom-control-watermark {
  font-size: 200%;
  font-weight: bolder;
  color: #aaa;
  text-shadow: #555;
}

.theme--light.v-input:not(.v-input--is-disabled) input{
  color:rgb(0, 0, 0);
  font-size: 12px;
}

.theme--light.v-textarea:not(.v-textarea--is-disabled) textarea{
  color:rgb(0, 0, 0);
  font-size: 12px;
}

.theme--light.v-select:not(.v-input--is-disabled) input{
  color:rgb(0, 0, 0);
  font-size: 12px;
}

.theme--light.v-input--checkbox .v-label {
  color:rgb(0, 0, 0);
  font-size: 12px;
}

.my-class.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th{
    font-size: 11px;
    padding: 0 10px;
    color: black !important;
}

.my-row-class{
  display: table-row;
}

.app-invoice-editable {
  .input-salesperson {
    width: 100px;
  }

  .select-invoice-to {
    width: 250px !important;
  }

  .header-inputs {
    width: 122px;
  }

  .add-products-form {
    .single-product-form {
      &:not(:first-child) {
        margin-top: 2rem;
      }
    }

    .header-spacer {
      // This is according to item actions width
      width: 39px;
    }
    .item-actions {
      @at-root {
        @include theme--child(add-products-form) using ($material) {
          .item-actions {
            border-left: thin solid map-deep-get($material, 'dividers');
          }
        }
      }
    }
  }
}
</style>
