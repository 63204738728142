import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPropsComparison(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/propertycomparison/leerlista', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    async fetchPropComparison(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/propertycomparison/leerregistro?id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    async fetchPropComparisonAdjustment(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/propertycomparison/leerregistroajuste?id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addPropComparison(ctx, propData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/propertycomparison/reciberegistro', { json: propData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMarkets(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/market/leerlista', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchPropertiesTypes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/propertieslist/leertipospropiedad', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchMarket(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/market/leermercadopornombre?id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCheckProperties(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/propertiescatalog/leerlistageo', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDeleteComparison(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/propertycomparison/borrarregistro?id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
